<template>
    <div v-if="showBanner" class="bg-gradient-to-l from-gradient-orange to-gradient-red px-4 py-3">
        <template v-if="hasAccount">
            <p class="text-sm text-white font-base">
                <i18n-t keypath="header.stripe_banner.unfinished">
                    <template #click>
                        <a @click.prevent="redirectToStripe" class="underline cursor-pointer font-semibold">
                            <i18n-t keypath="header.stripe_banner.unfinished_click"/>
                        </a>
                    </template>
                </i18n-t>
            </p>
        </template>
        <template v-else>
            <p class="text-sm text-white font-base">
                <i18n-t keypath="header.stripe_banner.inactive">
                    <template #click>
                        <a @click.prevent="openActivateStripeModal" class="underline cursor-pointer font-semibold">
                            <i18n-t keypath="header.stripe_banner.inactive_click"/>
                        </a>
                    </template>
                </i18n-t>
            </p>
        </template>
        <ActivateStripeModal v-model:show="show_stripe_modal" />
    </div>
</template>

<script>
import ActivateStripeModal from "@/components/modals/ActivateStripeModal.vue";
import { getStripeAccount } from "@/helper/api/StripeHelper";
import { createStripeAccount } from "@/helper/api/StripeHelper";
import message from "@/helper/store/messageHelper";
import {setLoading} from "@/helper/store/loadingSpinnerHelper";

export default {
    name: "Header",
    components: {
        // NotificationBell,
        ActivateStripeModal
    },
    data() {
        return { 
            show_stripe_modal: false,
            account: undefined
        }
    },
    created() {
        getStripeAccount().then(account => {
            this.account = account;
        })
    },
    computed:{
        user: function(){
            return this.$store.getters['getUser'];
        },
        showBanner() {
            if(this.user.is_trainer) {
                return false
            }
            return !this.hasAccount || this.hasInactiveAccount
        },
        hasAccount() {
            return this.account !== null
        },
        hasInactiveAccount() {
            return this.account?.capabilities?.transfers !== "active"
        }
    },
    methods: {
        redirectToStripe() {
            setLoading(true);
            createStripeAccount()
                .then((data) => {
                    window.location.href = data.account_link.url;
                })
                .catch(e => {
                    if (e.response?.data?.errors) {
                        this.errors = e.response.data.errors;
                    } else {
                        message.error(e.response?.data?.message ?? e.message);
                        throw e;
                    }
                })
                .finally(() => setLoading(false))
        },
        openActivateStripeModal() {
            this.show_stripe_modal = true;
        },
    }
}
</script>
