<template>
  <div class="flex flex-col min-h-screen bg-black">
    <Header />
    <div class="flex-grow py-12 mb-auto">
      <slot />
    </div>
    <footer>
      <Footer />
    </footer>
  </div>
</template>

<script>
import Header from "@/components/dashboard/Header";
import Footer from "@/components/dashboard/Footer";

export default {
  name: "Dashboard",
  components: {
    Header,
    Footer,
  },
};
</script>

<style scoped></style>
